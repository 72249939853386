import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, Link } from "gatsby";
import { Layout, Listing, Wrapper, Title } from '../components'
import website from '../../config/website'
import Menu from '../components/Menu'
import ListItem from "../components/Listing/ListItem";

const List = styled.ul`
  margin-top: 4rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
  position: relative;
`

const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  display: flex;
  align-items: center;
	height: 95vh;
	overflow:hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position:center center;
 
	 &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    top:0;
    left:0;
    width:100%;
    height:95vh;
    z-index:1;
  }
`

const HeroInner = styled(Wrapper)`
  padding-top: 8rem;
  padding-bottom: 8rem;
  h1 {
    graphqlmargin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const HeroText = styled.div`
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2.369rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`


const Item = styled.li`
  margin-bottom: 1.45rem;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;
  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
  }
`

const StyledLink = styled(Link)`
  font-size: 2.669rem;
  color:#ffffff;
  font-style: normal;
  position:relative;
  &:hover{
  text-decoration:none!important;
  }
  &:before{
   content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  visibility: hidden;
  -webkit-transform:scaleX(0);
  transform: scaleX(0);
  transform-origin: 0% 100%;
  -webkit-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  }
  &:hover:before {
    visibility: visible;
   -webkit-transform: scaleX(1);
   transform: scaleX(1);
  }
  // @media (max-width: ${props => props.theme.breakpoints.s}) {
  //   font-size: 1.777rem;
  // }
`

const StyledImage = styled.img`
  position: absolute;
  max-width: 50%;
  right: 0;
  top: 0;
  @media (max-width: 767px) {
    display: none;
  }
`
const IndexWrapper = Wrapper.withComponent('main')

class Index extends Component {

  constructor(props) {
    super(props)

    // 1. bind your functions in the constructor.
    this.mouseOver = this.mouseOver.bind(this)
    this.mouseOut = this.mouseOut.bind(this)
    this.state = {
      hover: false,
      position: 99,
      images:[]
    }
  }
// 2. bind it with fat arrows.
  mouseOver = (i) => {
    this.setState({ position: i})
  }

  mouseOut(i) {
    this.setState({ position: 99})
  }


  render() {
    const {
      data: { homepage, social, posts, projects },
    } = this.props;

    return (
      <>
        <Menu posts1={homepage.data.homepage_links} siteTitle={homepage.data.title.html} />

        <Layout >
          <Hero>
              <img className={this.state.position === 99 ? "bg-image vis" : "bg-image hidden "}  src={homepage.data.default_image.url}/>
            {homepage.data.homepage_links.map((post,i) => (
                <img className={ this.state.position === i ? "bg-image vis" : "bg-image hidden"} key={i} src={post.pages.document[0].data.preview_image.url}/>
            ))}
            <HeroInner>
              <List>
                {homepage.data.homepage_links.map((post,i) => (
                  <React.Fragment  key={post.pages.document[0].uid}>
                    <Item>
                      <StyledLink  onMouseEnter={()=>this.mouseOver(i)}
                                   onMouseLeave={()=>this.mouseOut(i)} to={post.pages.document[0].uid}>
                        {post.pages.document[0].data.title.text}
                      </StyledLink>
                    </Item>
                  </React.Fragment>
                ))}
              </List>
            </HeroInner>
          </Hero>
          {/*<IndexWrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}></IndexWrapper>*/}
        </Layout>

      </>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          html
        }
        email_link
        instagram_link
        linkedin_link
        resume_link
        default_image {
              url
            }
        homepage_links {
          pages {
            document {
              __typename
              ... on PrismicPost {
                data {
                  title {
                    text
                  }
                  preview_image {
                    url
                  }
                }
                uid
              }
            }
          }
        }
      }
    }
   
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            preview_image {
              url
            }
            date(formatString: "DD.MM.YYYY")
            
          }
        }
      }
    }

  }
`
